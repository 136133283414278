<template>
  <div class="tour">
    <!-- Step 1 modal -->
    <b-modal
      content-class="steps"
      ref="tour"
      id="tour"
      no-close-on-backdrop
      centered
    >
      <div class="step1" v-if="step == 1">
        <div class="title">How to start earning money!</div>
        <p class="subTitle">Step 1 (Discover the new offers)</p>
        <div class="steps__image">
          <img
            src="@/assets/images/usertour/step-1.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="button-row">
          <a
            @click="step = 2"
            href="javascript:void(0)"
            class="btn btn-primary full"
            >Next</a
          >
        </div>
      </div>
      <div v-if="step == 2">
        <div class="title">How to start earning money!</div>
        <p class="subTitle">Step 2 (Apply the offer)</p>
        <div class="steps__image">
          <img
            src="@/assets/images/usertour/step-2.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="inline-buttons">
          <a @click="step = 1" href="javascript:void(0)" class="btn btn-default"
            >Back</a
          >
          <a @click="step = 3" href="javascript:void(0)" class="btn btn-primary"
            >Next</a
          >
        </div>
      </div>
      <div v-if="step == 3">
        <div class="title">How to start earning money!</div>
        <p class="subTitle">Step 3 (Manage orders)</p>
        <div class="steps__image">
          <img
            src="@/assets/images/usertour/step-3.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="inline-buttons">
          <a @click="step = 2" href="javascript:void(0)" class="btn btn-default"
            >Back</a
          >
          <a href="javascript:void(0)" @click="step = 4" class="btn btn-primary"
            >Next</a
          >
        </div>
      </div>
      <div v-if="step == 4">
        <div class="title">How to start earning money!</div>
        <p class="subTitle">
          Step 4 (Make a print screen and send it to the customer, then wait if
          there are changes or the order is completed)
        </p>
        <div class="steps__image">
          <img
            src="@/assets/images/usertour/step-4.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="button-row">
          <a
            href="javascript:void(0)"
            @click="$bvModal.hide('tour')"
            class="btn btn-primary full"
            >Finish</a
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 1,
    };
  },
  mounted() {
    if (this.$store.state.auth.showTour == 1) {
      this.$refs["tour"].show();
      this.$store.commit("UPDATE_TOUR", 0);
    }
  },
};
</script>
